import axios from "axios";

const apiService = axios.create({
  baseURL: "https://api-sscasn.bkn.go.id/",
});

class HttpService {
  async getData(code: string, offset: number): Promise<any> {
    const response = await apiService.get(
      `2024/portal/spf?kode_ref_pend=${code}&offset=${offset}&pengadaan_kd=${2}`,
      {
        headers: {
          Origin: "http://localhost:3000",
          Referer: "http://localhost:3000/",
          "User-Agent":
            "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36",
        },
      }
    );
    return response.data;
  }
}

export default HttpService;
