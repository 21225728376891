import React, { useEffect, useState } from 'react';
import HttpService from './services/HttpService';
import { Table, Input, Card, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';

interface Data {
  meta: {
    total: number;
  };
  page: {
    total: number;
  };
  data: Array<{
    formasi_id: string;
    ins_nm: string;
    jp_nama: string;
    formasi_nm: string;
    jabatan_nm: string;
    lokasi_nm: string;
    jumlah_formasi: number;
    disable: boolean;
    gaji_min: string;
    gaji_max: string;
  }>;
}

interface DataSourceItem {
  key: React.Key;
  institutionName: string;
  jobPosition: string;
  formationName: string;
  jobTitle: string;
  locationName: string;
  formationCount: number;
  minSalary: string;
  maxSalary: string;
}

const Home: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<DataSourceItem[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const httpService = new HttpService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allResults: DataSourceItem[] = [];
        let offset = 0;
        for (let i = 0; i < 1000; i++) {
          const response = await httpService.getData("5080325", offset);
          if (response.data.length === 0) break;
          const mappedDataSource = mapApiResponseToDataSource(response.data, offset);
          allResults.push(...mappedDataSource);
          offset += 10;
        }

        setDataSource(allResults);
        setFilteredDataSource(allResults);
      } catch (error) {
        console.error('Error fetching data:', error);
        setDataSource([]);
        setFilteredDataSource([]);
      }
    };

    fetchData();
  }, []);

  const mapApiResponseToDataSource = (apiResponse: Data, offset: number): DataSourceItem[] => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });

    return apiResponse.data.filter(x => x.formasi_nm !== 'PENYANDANG DISABILITAS').map((item, index) => ({
      key: item.formasi_id,
      institutionName: item.ins_nm,
      jobPosition: item.jp_nama,
      formationName: `${item.formasi_nm} ${item.jp_nama}`,
      jobTitle: item.jabatan_nm,
      locationName: item.lokasi_nm,
      formationCount: item.jumlah_formasi,
      minSalary: formatter.format(parseInt(item.gaji_min, 10)),
      maxSalary: formatter.format(parseInt(item.gaji_max, 10)),
    }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = dataSource.filter(item =>
      item.institutionName.toLowerCase().includes(value) ||
      item.jobPosition.toLowerCase().includes(value) ||
      item.formationName.toLowerCase().includes(value) ||
      item.jobTitle.toLowerCase().includes(value) ||
      item.locationName.toLowerCase().includes(value) ||
      item.formationCount.toString().includes(value) ||
      item.minSalary.toLowerCase().includes(value) ||
      item.maxSalary.toLowerCase().includes(value)
    );

    setFilteredDataSource(filteredData);
  };

  const exportToCSV = () => {
    const headers = [
      'Id',
      'Instansi',
      'Formasi',
      'Jabatan',
      'Unit Kerja',
      'Jumlah kebutuhan',
      'Penghasilan Minimal',
      'Penghasilan Maksimal',
      'Detail',
    ];

    const rows = filteredDataSource.map(item => [
      item.key,
      item.institutionName,
      item.formationName,
      item.jobTitle,
      item.locationName,
      item.formationCount,
      item.minSalary,
      item.maxSalary,
      `https://sscasn.bkn.go.id/detailformasi/${item.key}`
    ]);

    const csvContent = [headers, ...rows]
      .map(e => e.join(';'))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', searchText ? `${searchText}.csv` : 'data.csv');
    link.click();
  };

  const columns = [
    {
      title: 'Instansi',
      dataIndex: 'institutionName',
      key: 'institutionName',
    },
    {
      title: 'Formasi',
      dataIndex: 'formationName',
      key: 'formationName',
    },
    {
      title: 'Jabatan',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'Unit Kerja',
      dataIndex: 'locationName',
      key: 'locationName',
    },
    {
      title: 'Jumlah kebutuhan',
      dataIndex: 'formationCount',
      key: 'formationCount',
      sorter: (a: any, b: any) => a.formationCount - b.formationCount,
    },
    {
      title: 'Penghasilan Minimal',
      dataIndex: 'minSalary',
      key: 'minSalary',
      sorter: (a: any, b: any) => {
        const aMinSalary = parseInt(a.minSalary.replace(/[^\d]/g, ''), 10);
        const bMinSalary = parseInt(b.minSalary.replace(/[^\d]/g, ''), 10);
        return aMinSalary - bMinSalary;
      },
    },
    {
      title: 'Penghasilan Maksimal',
      dataIndex: 'maxSalary',
      key: 'maxSalary',
      sorter: (a: any, b: any) => {
        const aMaxSalary = parseInt(a.maxSalary.replace(/[^\d]/g, ''), 10);
        const bMaxSalary = parseInt(b.maxSalary.replace(/[^\d]/g, ''), 10);
        return aMaxSalary - bMaxSalary;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: DataSourceItem) => (
        <Button
          type="link"
          onClick={() => window.open(`https://sscasn.bkn.go.id/detailformasi/${record.key}`, '_blank')}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Content className="site-layout" style={{ margin: 20 }}>
      <Card className='custom-card'>
        <Input
          placeholder="Search..."
          value={searchText}
          onChange={handleSearch}
          prefix={<SearchOutlined />}
          style={{ marginBottom: 16, width: 300 }}
        />
        <Button
          type="primary"
          onClick={exportToCSV}
          style={{ marginBottom: 16 }}
        >
          Export to CSV
        </Button>
        <Table dataSource={filteredDataSource} columns={columns} />
      </Card>
    </Content>
  );
}

export default Home;
